<template>
  <div class="d-flex flex-column flex-1">
    <module-navigation-bar title="Project Gantt">
      <template slot="module-nav-logo">
        <img
          v-if="isDaiwaLicense"
          alt=""
          height="40px"
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
        />
      </template>
    </module-navigation-bar>
    <div
      ref="ant-gantt-container"
      class="d-flex flex-column flex-1 pa-2"
      style="width: 100vw; height: calc(100vh - 46px - 46px)"
    >
      <div class="py-2 d-flex align-center" style="max-width: 100vw">
        <v-btn-toggle
          v-model="taskFilter"
          color="primary"
          dense
          mandatory
          rounded
        >
          <v-btn>{{ $t('modules.projectGantt.filters.allTasks') }}</v-btn>
          <v-btn>{{ $t('modules.projectGantt.filters.myTasks') }}</v-btn>
        </v-btn-toggle>
        <v-spacer />
        <v-btn-toggle
          v-model="ganttScale"
          color="primary"
          dense
          mandatory
          rounded
        >
          <v-btn v-for="option in ganttZoomLevels" :key="option.key"
            >{{ option.value }}
          </v-btn>
        </v-btn-toggle>
        <v-menu bottom left>
          <template #activator="{ on, attrs }">
            <v-icon class="ant-icon ml-2" dense v-bind="attrs" v-on="on"
              >mdi-dots-vertical
            </v-icon>
          </template>
          <v-list dense>
            <v-list-item
              @click="
                $refs['tasks-gantt'].showCriticalPath =
                  !$refs['tasks-gantt'].showCriticalPath
              "
            >
              <v-list-item-icon class="mr-2">
                <v-icon dense> mdi-map-marker-path</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $refs['tasks-gantt']?.showCriticalPath ? 'Hide' : 'Show' }}
                critical path
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                $refs['tasks-gantt'].treeToggle =
                  !$refs['tasks-gantt'].treeToggle
              "
            >
              <v-list-item-icon class="mr-2">
                <v-icon dense>
                  {{
                    $refs['tasks-gantt']?.treeToggle
                      ? 'mdi-triangle-down-outline'
                      : 'mdi-triangle-down'
                  }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $refs['tasks-gantt']?.treeToggle ? 'Close' : 'Open' }} all
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="$refs['tasks-gantt'].exportToMSProject()">
              <v-list-item-icon class="mr-2">
                <v-icon dense> mdi-microsoft</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Export to MS project</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$refs['tasks-gantt'].exportToPdf()">
              <v-list-item-icon class="mr-2">
                <v-icon dense> mdi-file-pdf-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Export to Pdf</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$refs['tasks-gantt'].exportToExcel()">
              <v-list-item-icon class="mr-2">
                <v-icon dense> mdi-file-excel-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Export to Excel</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-progress-linear
        :style="{ opacity: $wait.is('module.tasks-gantt.tasks.get') ? 1 : 0 }"
        color="primary"
        height="4px"
        indeterminate
      ></v-progress-linear>
      <dhme-project-gantt-chart
        v-if="!$wait.is('module.tasks-gantt.tasks.get')"
        ref="tasks-gantt"
        :tasks="tasks"
        style="max-width: 100vw; height: 0"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { DHME_PROJECT_GANTT } from '@/modules/modules';
import DhmeProjectGanttChart from '@/modules/daiwa-house-modular-europe/ProjectGantt/DhmeProjectGanttChart.vue';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import { stringToConstantColor } from '@/components/Charts/utils/tasks-chart.utils';
import moment from 'moment/moment';

export default {
  name: 'TasksGanttDHTMLX',
  components: { ModuleNavigationBar, DhmeProjectGanttChart },
  data() {
    return {
      ganttScale: 1,
      taskFilter: 0,
      ganttZoomLevels: [
        {
          key: 'year',
          value: this.$t('modules.projectGantt.ganttZoomLevels.yearly'),
        },
        {
          key: 'month',
          value: this.$t('modules.projectGantt.ganttZoomLevels.monthly'),
        },
        {
          key: 'week',
          value: this.$t('modules.projectGantt.ganttZoomLevels.weekly'),
        },
        {
          key: 'day',
          value: this.$t('modules.projectGantt.ganttZoomLevels.daily'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['project', 'selectedLicense', 'authenticatedUser']),
    ...mapGetters({
      tasks: 'tasksGantt/tasks',
      advancedTaskFilters: 'tasksGantt/advancedTaskFilters',
      isDaiwaLicense: 'isDaiwaLicense',
    }),
  },
  watch: {
    ganttScale(value) {
      this.$refs['tasks-gantt'].setZoomLevel(this.ganttZoomLevels[value].key);
    },
    taskFilter(value) {
      switch (value) {
        case 0:
          this.fetchAllProjectTasks();
          break;
        case 1:
          this.fetchMyProjectTasks();
          break;
        default:
      }
    },
    project: {
      immediate: true,
      async handler(value) {
        if (value) {
          await this.fetchAllProjectTasks();
          await this.fetchModuleData();
        }
      },
    },
  },
  created() {
    this.$store.commit('tasksGantt/tasks_add_advanced_filter', {
      selectedOption: {
        icon: 'mdi-inbox',
        text: 'project',
        value: 'project',
        operators: ['=', '!='],
      },
      selectedOperator: '=',
      selectedValues: [this.project],
      valueText: 'name',
    });
    this.$store.commit('tasksGantt/tasks_add_advanced_filter', {
      selectedOption: {
        icon: 'mdi-clipboard-check-outline',
        text: 'status',
        value: 'status',
        operators: ['=', '!='],
      },
      selectedOperator: '=',
      selectedValues: [{ title: 'open' }],
      valueText: 'title',
    });
  },
  destroyed() {
    this.$store.commit('tasksGantt/tasks_reset_advanced_filters');
  },
  methods: {
    formatDate(timestamp) {
      return moment.unix(timestamp).format('DD-MM-YYYY HH:mm');
    },
    stringToConstantColor,
    fetchModuleData() {
      if (this.project.modules.find((m) => m.route === DHME_PROJECT_GANTT)) {
        this.$store.dispatch('tasksGantt/fetchModuleData', {
          projectId: this.project.id,
          moduleId: this.project.modules.find(
            (m) => m.route === DHME_PROJECT_GANTT
          ).id,
          sessionId: this.$route.params.sessionId ?? null,
        });
      }
    },
    async fetchAllProjectTasks() {
      await this.$store.dispatch('tasksGantt/fetchTasks');
    },
    async fetchMyProjectTasks() {
      await this.$store.dispatch('tasksGantt/fetchTasks', [
        {
          column: 'assigned_to',
          operator: '=',
          values: [this.authenticatedUser.id],
        },
      ]);
    },
  },
};
</script>
